
<section class="my_account">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h4 class="accmain-head">My Account</h4>
        <div *ngIf="this.tellUsPageShowAndHide">
        <h4 class="accinner-head">Profile Details <a class="telus-modal-btn" href="javascript:void(0)"
            data-toggle="modal" data-target="#UpdateProfileSetting" (click)="open()">Edit Profile</a></h4>
        <ul class="account-body">
           <!-- {{tellUsAllFieldsArray|json}}
         {{tellUsValueWiyhAllFieldsArray|json}} -->
          <!-- {{output|json}}--- -->
          <li *ngFor="let ProData of output">{{ProData.title}}: <span *ngIf="ProData.type=='input_calender'; else el" > {{ProData.value=="" ? ProData.value: ProData.value*1000 | date: 'dd/MM/yyyy'}}</span>
            <ng-template #el><span>{{ProData.value}} 
            </span></ng-template>
          </li>
              
        </ul>
        
        <h4 class="accmain-head"></h4>
        </div>
        <h4 class="accinner-head">Account Details</h4>
        <ul class="account-body">
          <!-- data-toggle="modal" data-target="#add_name" -->
          <!-- <li>Name: <span class="italic"
              [ngClass]="{'name-text': first_name_text != '(Name not added)'}">{{first_name_text}}</span><a
              href="javascript:void(0)" data-toggle="modal" data-target="#add_name"
              (click)="open('namePop')">{{first_name_button_text}} Name</a></li> -->

          <li *ngIf="registeredBy != 'mobile'">Registered Email ID: {{registredEmail}}<a href="#" data-toggle="modal"
              data-target="#add_email" #openEmailModal (click)="open()">{{email_name_button_text}}
              Email ID</a></li>

          

          <li *ngIf="registeredBy == 'mobile'">Registered Mobile Number: <span class="italic"
              [ngClass]="{'mobile-number': registredMobileNumber != '(Mobile number not provided)'}">{{registredMobileNumber
              != '(Mobile number not provided)' ? registredMobileNumber : registredMobileNumber}}</span>
              <a href="#" data-toggle="modal" data-target="#change-mobile" (click)="open()"
              #openMobileNumberModal>{{mobile_name_button_text}} Mobile Number</a></li>

              

          <!-- Optional email -->
          <li>
            <span *ngIf="registeredBy == 'email'"><b>Mobile Number: </b>{{otherContact
              != '' ? otherContact : '(Optional): (Mobile Number Not Added)'}} <span
                *ngIf="otherContactVerification == '1'" style="color: #999;"> (Mobile Number Verified)</span></span>

            <span *ngIf="registeredBy == 'mobile'"><b>Email ID:</b> {{otherContact
              != '' ? otherContact : '(Optional): (Email ID not added)'}} <span *ngIf="otherContactVerification == '1'"
                style="color: #999;"> (Email ID Verified)</span></span>

            <a *ngIf="registeredBy == 'mobile' && otherContact == ''" href="#" data-toggle="modal"
              data-target="#optional-add-email" (click)="open()">Add Email ID</a>

            <a *ngIf="registeredBy == 'email' && otherContact == ''" href="#" data-toggle="modal"
              data-target="#optional-add-mobile" (click)="open()">Add Mobile Number</a>


            <a *ngIf="registeredBy == 'mobile' && otherContact != ''" href="#" data-toggle="modal"
              data-target="#optional-change-email" (click)="open()" #openOptionalEmailNumberModal>Change Email ID</a>

            <a *ngIf="registeredBy == 'email' && otherContact != ''" href="#" data-toggle="modal"
              data-target="#optional-change-mobile" (click)="open()">Change Mobile Number</a>

            <a *ngIf="registeredBy == 'mobile' && otherContact != '' && otherContactVerification == '0'" href="#"
              data-toggle="modal" data-target="#optional-verify-email" (click)="autosentOptionalEmailOTP('email')"
              style="clear: both;">Verify Email ID</a>

            <a *ngIf="registeredBy == 'email' && otherContact != '' && otherContactVerification == '0'" href="#"
              data-toggle="modal" data-target="#optional-verify-email" (click)="autosentOptionalEmailOTP('mobile')"
              style="clear: both;">Verify Mobile Number</a>


            <!-- <a style="clear: both;" *ngIf="otherContactVerification == '1'" href="#" data-toggle="modal">Verified</a> -->
          </li>


          <li class="pass-star">Password:<span>********</span> <a href="#" data-toggle="modal"
            data-target="#change_password" (click)="resetChangePasswordForm()">Change Password</a></li>
          <li *ngIf="parental_control == '1'">Parental Control: Active<a href="#" data-toggle="modal"
              data-target="#parental" (click)="sendParentalOtp()">Disable Parental Control</a></li>
          <li *ngIf="parental_control == '0'" class="parental-margin">Parental Control: <span class="child-not">
              Inactive</span><a href="#" data-toggle="modal" data-target="#parental" (click)="sendParentalOtp()">Enable
              Parental Control</a></li>
        </ul>
        <p class="parental-msg" *ngIf="parental_control == '0'">Parental Control option lets you restrict certain content to be displayed on Zonet Empai.
          This ensures that your child only has access to only that content which is suitable for their viewing. Turning
          on this option will automatically hide content that is not suitable for children under 13 years of age.</p>

          <p class="parental-msg" *ngIf="parental_control == '1'">
            Parental Control is enabled in your account. Content not suitable for children under 13 years of age is hidden. If you disable Parental Control, all content on Zonet Empai will be visible and available to watch.            
          </p>

        <div class="plan-details">
          <h4 class="accmain-head"></h4>
          <h4 class="accinner-head">Plan Details</h4>
          <div class="plan-body">

            <div *ngIf="objectLength(packDetail) > 0">
              <div *ngFor="let pDetails of packDetail">
                <!-- <div class="row">
                <div class="col-sm-2">
                  <p>{{pDetails.packDescription}}</p>
                </div>
                <div class="col-sm-6">
                  <p>Status</p>
                  <p *ngIf="pDetails.renewalYesNo == 'N'">You .... on {{dateFormat(pDetails.purchaseTime) }} and it is valid till {{dateFormat(pDetails.expiryDate) }}.</p>
                  <p *ngIf="pDetails.renewalYesNo == 'Y'">Your next billing is due on {{dateFormat(pDetails.expiryDate) }}.</p>
                </div>
                <div class="col-sm-4">
                  <div class="buy" *ngIf="pDetails.renewalYesNo == 'Y' && showCancelButton(pDetails.applicable_for)">
                    <p><a href="javascript:void(0)" (click)="open('cancel_subscription', pDetails)" data-toggle="modal"
                        data-target="#cancel_subscription_confirmation">Cancel Subscription</a></p>
                  </div>
                </div>
              </div> -->
                <div class="active-subscription">
                  <div class="row">
                    <div class="col-sm-3">
                      <p>{{pDetails.packDescription}}</p>
                    </div>
                    <div class="col-sm-9 col-md-6">
                      <p class="status  status-light">Status</p>

                      <p class="detail-active"
                        *ngIf="pDetails.renewalYesNo == 'N' && pDetails.cancelTime == '' && pDetails.pch_pur_mode_desc == 'Voucher'">
                        You have purchased <b>{{pDetails.packDescription}}</b> on {{dateFormat(pDetails.purchaseTime) }}
                        and it is valid till {{dateFormat(pDetails.expiryDate) }}.</p>
                      <p class="detail-active"
                        *ngIf="pDetails.renewalYesNo == 'Y' && pDetails.cancelTime == '' && pDetails.pch_pur_mode_desc != 'Voucher' ">
                        You have purchased <b>{{pDetails.packDescription}}</b> of <sapn>₹</sapn> {{pDetails.packPrice}}
                        on {{dateFormat(pDetails.purchaseTime) }} and it is valid till {{dateFormat(pDetails.expiryDate)
                        }}.</p>
                      <p class="detail-active"
                        *ngIf="pDetails.renewalYesNo == 'N' && pDetails.cancelTime == '' && pDetails.pch_pur_mode_desc != 'Voucher' ">
                        You have purchased <b>{{pDetails.packDescription}}</b> of <sapn>₹</sapn> {{pDetails.packPrice}}
                        on {{dateFormat(pDetails.purchaseTime) }} and it is valid till {{dateFormat(pDetails.expiryDate)
                        }}.</p>

                      <!-- <p class="detail-active" *ngIf="pDetails.renewalYesNo == 'Y' && pDetails.cancelTime == ''">Your next billing is due on {{dateFormat(pDetails.expiryDate) }}.</p> -->
                      <p class="detail-active" *ngIf="pDetails.cancelTime != ''">You cancelled subscription on
                        {{dateFormat(pDetails.cancelTime) }} and pack is valid till {{dateFormat(pDetails.expiryDate)
                        }}.</p>
                      <p class="detail-active status-light">Purchased through {{pDetails.pch_pur_mode_desc}}.</p>
                      <!-- <p class="not_eligible" *ngIf="pDetails.renewalYesNo == 'Y' && !showCancelButton(pDetails.applicable_for)">You are not eligible to cancel pack</p> -->


                      <div class="buy" *ngIf="pDetails.renewalYesNo == 'Y' && pDetails.customerStatus != 'D'">
                        <p><a href="javascript:void(0)" (click)="open('cancel_subscription', pDetails)"
                            data-toggle="modal" data-target="#cancel_subscription_confirmation">Cancel Subscription</a>
                        </p>
                      </div>
                      <!-- <div class="buy" *ngIf="pDetails.renewalYesNo == 'Y' && !showCancelButton(pDetails.applicable_for)">
                        <p class="not_eligible">You are not eligible to cancel pack</p>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              
              <div class="col-sm-9">
                <div class="row" *ngIf="objectLength(packDetail) > 0">
                  <div class="col-md-4"></div>
                  <div class="col-md-8">
                      <p class="detail-active status-light text-italic">
                        Your active pack gives you access to limited content or is valid for a limited time period. To
                        purchase auto renewable Premium pack click Buy Now.
                      </p>
                  </div>
                </div>
                <div *ngIf="objectLength(packDetail) == 0">
                  <p>You don't have any active pack.</p>
                  <p>Please buy a pack to view premium content.</p>
                </div>
              
              </div>
              <div class="col-sm-3">

                <div class="buy" *ngIf="canBuy">
                  <button class="btn btn-default buy-btn" routerLink="/subscribe/choseplan">Buy Now</button>
                  <p><a class="f13" href="javascript:void(0)" data-toggle="modal" data-target="#promo"
                      (click)="open('redeem_code')">Redeem Gift Card or Promo Code</a></p>
                </div>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-md-12">
              <h4 class="accmain-head"></h4>
                <p class="text-right"><a href="javascript:void(0)" data-backdrop="static" data-toggle="modal" data-target="#account_delete" class="f13">Delete my account</a></p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</section>

<!--add promocode subscription -->
<div class="modal auth" id="promo">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">
      <form [formGroup]="addPromoForm" (ngSubmit)="applypromoCode()">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Promo Code/Activation Code</h4>
          <button type="button" class="close" data-dismiss="modal" #closePromoButton>&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div class="reedem_text">
            <p class="otp-center">Redeem your Promo Code</p>
            <p class="promo-code">Enter the promo code and get your subscription now
              <!-- If you have a promo code, enter it here -->
            </p>
          </div>
          <div class="form-group input-group promo-input">
            <input type="text" formControlName="promocode" autocomplete="off" class="form-control" id="promocode"
              placeholder="Enter your promo code">
            <div
              *ngIf="addPromoForm.get('promocode').invalid && (addPromoForm.get('promocode').dirty || addPromoForm.get('promocode').touched)"
              class="error _require_filed">
              <span *ngIf="addPromoForm.get('promocode').errors.required">
                Promocode is required.
              </span>
            </div>

          </div>


          <div class="_custom_require_f">
            <div class="error _require_filed promocode_error"
              style="margin-top: 30px !important;text-align:center !important;">
              <span>{{promocode_error}}</span>
            </div>
          </div>


          <button class="btn btn-default auth-btn profile-btn promo-btn"
            [disabled]="!addPromoForm.valid">REDEEM</button>

          <a #add_promocodesuccess data-toggle="modal" data-target="#add_promocodesuccess"></a>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- add promocode subscription -->


<!--Add promocode Success-->
<div class="modal  auth" id="add_promocodesuccess">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="success-add">
          <img src="../../../assets/img/success.png">
          <p>Promocode has been applied successfully.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Add promocode Success-->

<!--Cancel subscription -->


<!-- Cancel Subscription is depend of APi response applicable_for field -->
<a data-toggle="modal" data-target="#cancel_subscription_confirmation" style="visibility:hidden !important;"
  #cancel_subscription_confirmation></a>
<div class="modal  auth" id="cancel_subscription_confirmation">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{canCancel?'Going Too Soon!':'Cancel Subscription'}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeRemoveSubscriptionModal>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-body-padding cancel_subscription_modal">
        <p class="color-white " *ngIf="canCancel">
          You won't be able to watch the premium content. Are you sure you want to unsubscribe?
        </p>
        <p class="color-white " *ngIf="!canCancel">
          Your membership is managed By
          {{cancelPackDetail?.pch_pur_mode_desc}}
        </p>
        <p class="color-white" *ngIf="!canCancel">To cancel your subscription, please follow the below steps:
        </p>


        <p class="error" *ngIf="remove_subscription_error != ''">{{remove_subscription_error}}</p>
        <div class="action-btn">
          <div *ngIf="canCancel">
            <button type="button" class="btn btn-primary yes" (click)="removeSubscription()">Yes</button>
            <button type="button" class="btn btn-secondary no" data-dismiss="modal" (click)="closeBox()">No</button>
            <a href="" data-toggle="modal" data-target="#pack_removed_success" #showPackSuccess></a>
          </div>

          <div *ngIf="!canCancel">
            <div class="_cancel_subs">
              <ul *ngIf="cancelPackDetail?.pch_pur_mode_desc == 'iTunes'">
                <li>Sign in on your iOS device using the Apple ID with which you purchased this subscription</li>
                <li>Open Settings</li>
                <li>Click on the option "iTunes & App Store"</li>
                <li>Click on Apple ID</li>
                <li>Click on view Apple ID</li>
                <li>Login with your Apple credentials</li>
                <li>Click on Subscriptions</li>
                <li>Select pack you wish to unsubscribe</li>

              </ul>
              <ul *ngIf="cancelPackDetail?.pch_pur_mode_desc == 'Google Wallet'">
                <li> Sign in to Play Store using the account with which you purchased this subscription</li>
                <li>Select Subscriptions</li>
                <li>Click on the option "iTunes & App Store"</li>
                <li>Click on Unsubscribe</li>
              </ul>
              <ul *ngIf="cancelPackDetail?.pch_pur_mode_desc == 'Amazon'">
                <li>Go to Your Memberships and Subscriptions in your Amazon account</li>
                <li>Select Manage Subscriptions next to the subscription you'd like to cancel</li>
                <li>Click the link under Advance Controls to be directed to the main subscription page. On this page,
                  Click on Actions drop down against the subscription you want to discontinue and choose the 'Turn off
                  auto renewal' option to cancel your subscription.</li>
              </ul>
            </div>


            <button type="button" class="btn btn-primary yes" (click)="closeCancelSubModal()">OKAY</button>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
<!-- Cancel subscription -->


<!--Cancel pack Success-->
<div class="modal  auth" id="pack_removed_success">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body modal-body-padding">
        <div class="success-add">
          <img src="../../../assets/img/success.png">
          <p>Your cancellation has been confirmed. The cancellation will be effective at the end of your current billing
            period on {{ dateFormatText(packExpiryDate) }} . Thank you for choosing Platform8.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Cancel pack Success-->


<!--End Episode list -->

<!--Add Name-->
<div class="modal  auth" id="add_name">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">
      <form [formGroup]="addNameForm" (ngSubmit)="updateName()">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">{{first_name_button_text}} Name</h4>
          <button type="button" class="close" data-dismiss="modal" #closebutton>&times;</button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <div class="form-group input-group ">
            <input autocomplete="off" type="text" required (keyup)="removeSpace($event)" formControlName="username"
              class="form-control" id="enter_name" placeholder="Enter Name" maxlength="20">
            <div
              *ngIf="addNameForm.get('username').invalid && (addNameForm.get('username').dirty || addNameForm.get('username').touched)"
              class="error _require_filed">
              <span *ngIf="addNameForm.get('username').errors.required">
                Name is required.
              </span>
            </div>
          </div>

          <div class="error">{{name_error}}</div>

          <button class="btn btn-default auth-btn profile-btn" [disabled]="!addNameForm.valid">Submit</button>

          <a #add_namesuccess data-toggle="modal" data-target="#add_namesuccess"></a>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- Add Name-->

<!--Add Name Success-->
<div class="modal  auth" id="add_namesuccess">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="success-add">
          <img src="../../../assets/img/success.png">
          <!-- <p>Your name has been changed successfully.</p> -->
          <!-- <p>Your name has been {{ first_name_text == '' ? 'added' : 'updated'}} successfully.</p> -->
          <p>Your name has been {{ first_name_text == '' ? 'added' : 'updated'}}.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Add Name Success-->

<!--Add Email-->
<div class="modal  auth" id="add_email">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">{{email_name_button_text}} Account Email ID</h4>
        <button type="button" class="close" data-dismiss="modal" #closeEmailbutton (click)="closeEmailBtn()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="addEmailForm" (ngSubmit)="sentEmailOTP()">
          <div class="form-group input-group ">
            <input type="email" class="form-control" id="enter_email" formControlName="email" autocomplete="off"
              placeholder="Enter new email ID" (keyup)="clearEmailError($event)">
            <div
              *ngIf="addEmailForm.get('email').invalid && (addEmailForm.get('email').dirty || addEmailForm.get('email').touched)"
              class="error _require_filed">
              <span *ngIf="addEmailForm.get('email').errors.required">
                Please enter new email ID.
              </span>
              <span *ngIf="addEmailForm.get('email').errors?.pattern">
                Please enter valid email ID
              </span>
            </div>

            <div class="error _require_filed"><span>{{email_error}}</span></div>

          </div>


          <button class="btn btn-default auth-btn profile-btn" [disabled]="!addEmailForm.valid">Submit</button>
          <a href="#" #showEmailOTPModal data-toggle="modal" data-target="#email_otp"></a>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Add email end-->


<!--Email change otp-->
<div class="modal  auth" id="email_otp">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">{{email_name_button_text}} Account Email ID</h4>
        <button type="button" class="close" data-dismiss="modal" #closeEmailOTPModal (click)="closeEmailOTPBtn()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="addEmailOTPForm" (ngSubmit)="verifyEmailOTP()">
          <div class="_OTP_field">
            <!-- <p class="otp-center">One Time Password has been sent to</p> -->
            <p class="otp-center">Please enter the OTP we have sent via SMS to</p>
            <p class="otp-email">{{email_name_text}} <i class="fa fa-pencil white-color pointer_hand"
                data-toggle="tooltip" data-placement="top" title="Edit email id" (click)="editEmail()"></i></p>
          </div>
          <div class="form-group input-group ">
            <input type="text" pattern="\d*" class="form-control" formControlName="verify_email_otp"
              (keyup)="clearEmailError($event)" autocomplete="off" placeholder="Enter OTP" maxlength="6">

            <div
              *ngIf="(addEmailOTPForm.get('verify_email_otp').invalid) && (addEmailOTPForm.get('verify_email_otp').dirty || addEmailOTPForm.get('verify_email_otp').touched)"
              class="error _require_filed ">
              <span *ngIf="addEmailOTPForm.get('verify_email_otp').errors.required">
                Please enter the OTP
              </span>
              <span *ngIf="addEmailOTPForm.get('verify_email_otp').errors.minlength">
                OTP must be at least 6 digit long.
              </span>
              <span
                *ngIf="addEmailOTPForm.get('verify_email_otp').errors.NaN && !addEmailOTPForm.get('verify_email_otp').errors.minlength">
                OTP must be at least 6 digit long.
              </span>
            </div>
            <!-- <div class="error">{{email_error}}</div> -->
            <!-- <div class="error _require_filed"><span>{{email_error}}</span></div> -->

          </div>
          <!-- <a href="javascript:void(0);" (click)="resendEmailOTP()" class="resend" > Resend </a> -->

          <span *ngIf="error_code != '429'">
            <a (click)="resendEmailOTP(1)" href="javascript:void(0);" class="resend" *ngIf="emailotpInt == '00:00'">Resend OTP</a>
            <a class="resend" *ngIf="emailotpInt != '00:00'" style="color: #ccc;opacity: 0.5;">Resend OTP</a>
          </span>
          <div class="invalid-feedback _oth_requiered _time_otp"
            style="display: block !important; text-align:center !important;">
            <span *ngIf="otpInt != '00:00' || error_code">
              <!-- OTP successfully resent to {{email_name_text}} if OTP not received, please go back and try again or
                contact customercare@digivive.com for support  -->
              {{email_otp_error}}
            </span>
            <!-- <span *ngIf="error_code == '201'">
          OTP successfully resent to {{email_name_text}} if OTP not received, please go back and try again or
              contact customercare@digivive.com for support 
              {{email_error}}
        </span> -->
          </div>


          <!-- <div class="success">{{email_otp_success_msg}}</div> -->
          <button class="btn btn-default auth-btn profile-btn" [disabled]="!addEmailOTPForm.valid">Confirm</button>

          <a href="#" #showEmailSuccess data-toggle="modal" data-target="#email_change_success"></a>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Email change otp -->

<!-- Email change Success-->
<div class="modal  auth" id="email_change_success">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="success-add">
          <img src="../../../assets/img/success.png">
          <!-- <p>Your email ID has been successfully changed.</p> -->
          <p>Email ID successfully changed.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Email change Success-->


<!--Add Mobile-->
<div class="modal  auth" id="change-mobile">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">{{mobile_name_button_text}} Mobile Number</h4>
        <button type="button" class="close" data-dismiss="modal" #closeMobilebutton (click)="closeMobileBtn()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="addMobileForm" (ngSubmit)="sentMobileOTP()">
          <div class="form-group input-group ">
            <input type="text" class="form-control" formControlName="mobile_number" autocomplete="off" id="enter_mobile"
              (keyup)="clearMobileError($event, 'mobile')" (keypress)="isNumberKey($event)"
              placeholder="Enter new mobile number" minlength="10" maxlength="10">
            <div *ngIf="mobileNumberControl.invalid && (mobileNumberControl.dirty || mobileNumberControl.touched)"
              class="error _require_filed">
              <span *ngIf="mobileNumberControl.errors.required">
                Please enter new mobile number

              </span>
              <span *ngIf="mobileNumberControl.errors.minlength">
                Please enter the 10 digit mobile number.
              </span>

              <span *ngIf="mobileNumberControl.errors.NaN && !mobileNumberControl.errors.minlength">
                Please enter the 10 digit mobile number.
              </span>
            </div>
            <div class="error _require_filed" *ngIf="mobile_error != ''">
              <span>
                {{mobile_error}}
              </span>
            </div>
          </div>

          <!-- <div class="error">{{mobile_error}}</div> -->
          <button class="btn btn-default auth-btn profile-btn" [disabled]="!addMobileForm.valid">Submit</button>
          <a href="#" #showOTPModal data-toggle="modal" data-target="#change-mobile_otp"></a>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Add mobile end-->


<!--Mobile change otp-->
<div class="modal  auth" id="change-mobile_otp">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">{{mobile_name_button_text}} Mobile Number</h4>
        <button type="button" class="close" #closeOTPModal data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="addMobileOTPForm" (ngSubmit)="verifyMobileOTP()">

          <div class="_otp_Pwd_field">
            <!-- <p class="otp-center">One Time Password has been sent to</p> -->
            <p class="otp-center">Please enter the OTP we have sent via SMS to</p>
            <p class="otp-email">{{mobile_name_text}} <i class="fa fa-pencil white-color pointer_hand"
                data-toggle="tooltip" data-placement="top" title="Edit mobile number" (click)="editMobileNumber()"></i>
            </p>
          </div>

          <div class="form-group input-group">
            <input type="text" pattern="\d*" class="form-control" formControlName="verify_otp"
              (keyup)="clearMobileError($event, 'otp')" id="verify_otp" placeholder="Enter OTP" autocomplete="off"
              maxlength="6">
            <div
              *ngIf="(addMobileOTPForm.get('verify_otp').invalid) && (addMobileOTPForm.get('verify_otp').dirty || addMobileOTPForm.get('verify_otp').touched)"
              class="error _require_filed">
              <span *ngIf="addMobileOTPForm.get('verify_otp').errors.required">
                Please enter the OTP
              </span>

              <span *ngIf="addMobileOTPForm.get('verify_otp').errors.minlength">
                OTP must be at least 6 digit long.
              </span>

              <span
                *ngIf="addMobileOTPForm.get('verify_otp').errors.NaN && !addMobileOTPForm.get('verify_otp').errors.minlength">
                OTP must be at least 6 digit long.
              </span>
            </div>
            <!-- <div class="error _require_filed">
            <span>{{mobile_error}}</span>
          </div> -->

          </div>

          <!-- <a href="javascript:void(0);" (click)="resendMobileOTP('1')" class="resend" > Resend </a> -->

          <span *ngIf="error_code != '429'">
            <a (click)="resendMobileOTP(1)" class="resend" *ngIf="otpInt == '00:00'">Resend OTP</a>
            <a class="resend" *ngIf="otpInt != '00:00'" style="color: #ccc;opacity: 0.5;">Resend OTP</a>
          </span>
          <div class="invalid-feedback _oth_requiered _time_otp"
            style="display: block !important; text-align:center !important;">
            <span *ngIf="otpInt != '00:00' || error_code">
              <!-- Next Otp will available in {{otpInt}} Seconds -->
              <!-- {{mobile_error}} -->
              <!-- <span *ngIf="mobile_otp_error != '' ">{{mobile_otp_error}}</span> -->
              <!-- OTP successfully resent to {{mobile_name_text}} -->

            </span>

            <div *ngIf="error_code == '429'">
            <span *ngIf="fristSring != '' ">{{fristSring}}
              <a *ngIf="fristSring != '' " routerLink="/contactus" routerLinkActive="active" aria-disabled="true"
                data-dismiss="modal">Contact</a>
              {{secondSring}}</span>
            </div>
            <div *ngIf="error_code != '429'">{{mobile_otp_error}}</div>
          </div>

          

          <button class="btn btn-default auth-btn profile-btn" [disabled]="!addMobileOTPForm.valid">Confirm</button>

          <a href="#" #showMobileSuccess data-toggle="modal" data-target="#mobile_change_success"></a>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Mobile change otp -->

<!-- Mobile change Success-->
<div class="modal  auth" id="mobile_change_success">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="success-add">
          <img src="../../../assets/img/success.png">
          <!-- <p>Your mobile number has been {{mobile_name_button_text}} successfully.</p> -->
          <p>Mobile number successfully {{mobile_name_button_text}}.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile change Success-->

<!--Change Paassword-->
<div class="modal  auth" id="change_password">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Change Password</h4>
        <button type="button" class="close" data-dismiss="modal" #changePasswordClose (click)="changePassClose()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">

        <div class="error _require_filed align_center">
          <span>{{change_password_error}}</span>
        </div>
        <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()" autocomplete="off">
          <div class="form-group input-group">
            <input type="password" class="form-control" (keyup)="clearError($event,'oldpass')" autocomplete="nope"
              formControlName="old_pass" id="old_pass" placeholder="Enter Old Password" #oldpass
              (focus)="setAutoFillOff($event)">
            <div class="input-group-prepend">
              <span class="input-group-text pointer_hand" (click)="showOldPass()"><i class="fa fa-eye"
                  #oldEyeEvent></i></span>
            </div>
            <div
              *ngIf="(changePasswordForm.get('old_pass').invalid) && (changePasswordForm.get('old_pass').dirty || changePasswordForm.get('old_pass').touched)"
              class="error _require_filed">
              <span *ngIf="changePasswordForm.get('old_pass').errors.required">
                Old password is required.
              </span>
              <span *ngIf="changePasswordForm.get('old_pass').errors.minlength">
                Old password should be at least 8 characters.
              </span>
            </div>
            <div class="error _require_filed" *ngIf="changePasswordForm.get('old_pass').valid && wrongOldPwd != ''">
              <span>{{wrongOldPwd}}</span>
            </div>
          </div>


          <div class="form-group input-group">
            <input type="password" class="form-control" id="new_pass" (keyup)="clearError($event,'newpass')"
              formControlName="new_pass" placeholder="Enter New Password" #npass autocomplete="off">
            <div class="input-group-prepend">
              <span class="input-group-text pointer_hand" (click)="showNewPass()"><i class="fa fa-eye"
                  #newEyeEvent></i></span>
            </div>
            <div
              *ngIf="changePasswordForm.get('new_pass').invalid && (changePasswordForm.get('new_pass').dirty || changePasswordForm.get('new_pass').touched)"
              class="error _require_filed">
              <span *ngIf="changePasswordForm.get('new_pass').errors.required">
                New password is required.
              </span>
              <span *ngIf="changePasswordForm.get('new_pass').errors.minlength">
                New password should be at least 8 characters.
              </span>
            </div>
            <div class="error _require_filed" *ngIf="changePasswordForm.get('new_pass').valid && wrongNewPwd != ''">
              <span>{{wrongNewPwd}}</span>
            </div>
          </div>

          <div class="form-group input-group">
            <input type="password" class="form-control" id="re_enter" (keyup)="clearError($event,'cnfrmpass')"
              formControlName="confirm_pass" placeholder="Re-enter New Password" #confirmPass autocomplete="off">
            <div class="input-group-prepend">
              <span class="input-group-text pointer_hand" (click)="showCPass()"><i class="fa fa-eye"
                  #confirmEyeEvent></i></span>
            </div>
            <div
              *ngIf="changePasswordForm.get('confirm_pass').invalid && (changePasswordForm.get('confirm_pass').dirty || changePasswordForm.get('confirm_pass').touched)"
              class="error _require_filed">
              <!-- <span *ngIf="changePasswordForm.get('confirm_pass').errors.required">
              Confirm password is required.
            </span> -->

              <!-- <span *ngIf="changePasswordForm.get('confirm_pass').errors.minlength">
              Confirm password must be at least 8 characters long.
            </span> -->

              <!-- <span *ngIf="changePasswordForm?.errors?.mismatchedPasswords">
              <span> 
                Confirm Password does not match.
              </span>
            </span> -->

            </div>
            <div class="error _require_filed"
              *ngIf="!changePasswordForm?.errors?.mismatchedPasswords && changePasswordForm.get('confirm_pass').valid && wrongCnfrmPwd != ''">
              <span>{{wrongCnfrmPwd}}</span>
            </div>
            <span class="error _require_filed"
              *ngIf="changePasswordForm?.errors?.mismatchedPasswords && (changePasswordForm.get('confirm_pass').dirty || changePasswordForm.get('confirm_pass').touched)">
              <span>
                Confirm Password does not match.
              </span>
            </span>

          </div>


          <button class="btn btn-default auth-btn profile-btn" [disabled]="!changePasswordForm.valid">Confirm</button>
          <a href="#" #changePasswordSuccess data-toggle="modal" data-target="#change_password_success"></a>
          <!-- [disabled]="!changePasswordForm.valid" -->
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Change Password-->

<!-- Change Password Success-->
<div class="modal  auth" id="change_password_success">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="success-add">
          <img src="../../../assets/img/success.png">
          <p>Your password has been successfully changed.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Change Password Success-->


<!--Parental Control-->
<div class="modal auth" id="parental">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Parental Controls</h4>
        <button type="button" class="close" data-dismiss="modal" #closeParentalForm>&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="_par_control">
          <p class="otp-center">Please enter the OTP we have sent to your registered </p>
          <p class="otp-email" *ngIf="registeredBy != 'mobile'">email ID {{registredEmail}}</p>
          <p class="otp-email" *ngIf="registeredBy == 'mobile'">mobile no +91-{{showregistredMobileNumber}}</p>
        </div>
        <form [formGroup]="addParentalForm" (ngSubmit)="submitParentalOtp()">
          <div class="form-group input-group ">
            <input type="text" pattern="\d*" class="form-control" (keyup)="removeParentalError($event)"
              formControlName="parental_enter_otp" id="enter_otp" placeholder="Enter OTP" autocomplete="off"
              maxlength="6">

            <div
              *ngIf="addParentalForm.get('parental_enter_otp').invalid && (addParentalForm.get('parental_enter_otp').dirty || addParentalForm.get('parental_enter_otp').touched)"
              class="error _require_filed">
              <span *ngIf="addParentalForm.get('parental_enter_otp').errors.required && parental_otp_error == ''">
                Please enter the OTP
              </span>
              <span *ngIf="addParentalForm.get('parental_enter_otp').errors.minlength && parental_otp_error == ''">
                OTP must be at least 6 digit long.
              </span>
              <span
                *ngIf="addParentalForm.get('parental_enter_otp').errors.NaN && !addParentalForm.get('parental_enter_otp').errors.minlength && parental_otp_error == ''">
                OTP must be at least 6 digit long.
              </span>
            </div>
            <div class="error _require_filed">
              <!-- <span>{{parental_otp_error}}</span> -->
            </div>
          </div>
          <!-- <a href="javascript:void(0)" class="resend" (click)="sendParentalOtp()">Resend</a> -->

          <span *ngIf="error_code != '429'">
            <a (click)="sendParentalOtp(1)" href="javascript:void(0);" class="resend"
              *ngIf="parentotpInt == '00:00'">Resend OTP</a>
            <a class="resend" *ngIf="parentotpInt != '00:00'" style="color: #ccc;opacity: 0.5;">Resend OTP</a>
          </span>
          <div class="invalid-feedback _oth_requiered _time_otp"
            style="display: block !important; text-align:center !important;">
            <span *ngIf="parentotpInt != '00:00' || error_code">
              <!-- OTP successfully resent to  -->
              {{parental_otp_error}}

              <!-- 
                <span *ngIf="registeredBy != 'mobile'">{{registredEmail}}</span>
                <span *ngIf="registeredBy == 'mobile'">+91-{{registredMobileNumber}}</span>
                  if OTP not received, please go back and try again or
                      contact customercare@digivive.com for support 
                </span> 
            -->
            </span>
          </div>

          <!-- <div class="success _otp_sucess">{{parental_otp_success}}</div> -->

          <button class="btn btn-default auth-btn profile-btn" [disabled]="!addParentalForm.valid">Confirm</button>

          <a data-toggle="modal" data-target="#add_parentalsuccess" #showParentalSuccess></a>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Parental Control-->

<!--Parental Control Success-->
<div class="modal auth" id="add_parentalsuccess">
  <div class="modal-dialog">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="success-add">
          <img src="../../../assets/img/success.png">
          <p>Parental Controls has been successfully {{ parental_control_success_text}}.</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Parental Control Success-->



<!-- --------------------------------------------------------------------------------------------------- -->

<!--Add Optional Email-->
<div class="modal  auth" id="optional-add-email">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Add Your Email ID</h4>
        <button type="button" class="close" data-dismiss="modal" #closeOptionalEmailbutton (click)="closeOptionalEmailBtn()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="addOptionalEmailForm" (ngSubmit)="sentOptionalEmailOTP('addemail')">
          <div class="form-group input-group ">
            <input type="email" class="form-control" id="enter_optional_email" formControlName="optional_email"
              autocomplete="off" placeholder="Enter Your Email ID" (keyup)="clearEmailError($event)">
            <div
              *ngIf="addOptionalEmailForm.get('optional_email').invalid && (addOptionalEmailForm.get('optional_email').dirty || addOptionalEmailForm.get('optional_email').touched)"
              class="error _require_filed">
              <span *ngIf="addOptionalEmailForm.get('optional_email').errors.required">
                Please enter new email ID.
              </span>
              <span *ngIf="addOptionalEmailForm.get('optional_email').errors?.pattern">
                Please enter valid email ID
              </span>
            </div>

            <div class="error _require_filed"><span>{{email_error}}</span></div>

          </div>

          <button class="btn btn-default auth-btn profile-btn" [disabled]="!addOptionalEmailForm.valid">Submit</button>
          <a href="#" #showOptionalEmailOTPModal data-toggle="modal" data-target="#optional_email_otp"></a>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Add Optional email end-->



<!-- change  Optional email----------------- -->
<div class="modal  auth" id="optional-change-email">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Change Your Email ID</h4>
        <button type="button" class="close" data-dismiss="modal" #closeOptionalEmailChangebutton (click)="closeOptionalEmailChangeBtn()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="changeOptionalEmailForm" (ngSubmit)="sentOptionalEmailOTP('changeemail')">
          <div class="form-group input-group ">
            <input type="email" class="form-control" id="enter_optional_email" formControlName="change_optional_email"
              autocomplete="off" placeholder="Enter Your Email ID " (keyup)="clearEmailError($event)">
            <div
              *ngIf="changeOptionalEmailForm.get('change_optional_email').invalid && (changeOptionalEmailForm.get('change_optional_email').dirty || changeOptionalEmailForm.get('change_optional_email').touched)"
              class="error _require_filed">
              <span *ngIf="changeOptionalEmailForm.get('change_optional_email').errors.required">
                Please enter new email ID.
              </span>
              <span *ngIf="changeOptionalEmailForm.get('change_optional_email').errors?.pattern">
                Please enter valid email ID
              </span>
            </div>

            <!-- <div class="error _require_filed"><span>{{email_error}}</span></div> -->

          </div>

          <button class="btn btn-default auth-btn profile-btn"
            [disabled]="!changeOptionalEmailForm.valid">Submit</button>
          <a href="#" #showOptionalEmailOTPModal data-toggle="modal" data-target="#optional_email_otp"></a>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- change end----------------------- -->



<!-- Optional Email verify otp-->
<div class="modal  auth" id="optional-verify-email">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title" *ngIf="registeredBy == 'email'">Verify Your Mobile Number</h4>
        <h4 class="modal-title" *ngIf="registeredBy == 'mobile'">Verify Your Email ID</h4>
        <button type="button" class="close" data-dismiss="modal" #closeVerifyOptionalEmailOTPModal (click)="closeVerifyOptionalEmailBtn()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="addOptionalEmailOTPForm" (ngSubmit)="verifyOptionalEmailOTP()">
          <div class="_OTP_field">
            <!-- <p class="otp-center">One Time Password has been sent to</p> -->
            <p class="otp-center" *ngIf="registeredBy == 'mobile'">Please enter the OTP we have sent to</p>
            <p class="otp-center" *ngIf="registeredBy == 'email'">Please enter the OTP we have sent via SMS to</p>
            <p class="otp-email">{{otherContact}}
              <!-- <i class="fa fa-pencil white-color pointer_hand" data-toggle="tooltip" data-placement="top" title="Edit email id" (click)="editEmail()"></i> -->
            </p>
          </div>
          <div class="form-group input-group ">
            <input type="number" pattern="\d*" class="form-control" id="enter_otp"
              formControlName="verify_optional_email_otp" (keyup)="clearEmailError($event)" autocomplete="off"
              placeholder="Enter OTP" maxlength="6" (keypress)="commonService.otpLimit($event)">

            <div
              *ngIf="(addOptionalEmailOTPForm.get('verify_optional_email_otp').invalid) && (addOptionalEmailOTPForm.get('verify_optional_email_otp').dirty || addOptionalEmailOTPForm.get('verify_optional_email_otp').touched)"
              class="error _require_filed ">
              <span *ngIf="addOptionalEmailOTPForm.get('verify_optional_email_otp').errors.required">
                Please enter the OTP
              </span>
              <span *ngIf="addOptionalEmailOTPForm.get('verify_optional_email_otp').errors.minlength">
                OTP must be at least 6 digit long.
              </span>
              <span
                *ngIf="addOptionalEmailOTPForm.get('verify_optional_email_otp').errors.NaN && !addOptionalEmailOTPForm.get('verify_optional_email_otp').errors.minlength">
                OTP must be at least 6 digit long.
              </span>
            </div>
            <!-- <div class="error">{{email_error}}</div> -->
            <!-- <div class="error _require_filed"><span>{{email_error}}</span></div> -->

          </div>
          <!-- <a href="javascript:void(0);" (click)="resendEmailOTP()" class="resend" > Resend </a> -->

          <span *ngIf="optional_email_error_code != '429'">
            <span style="color: white;"></span>
            <a (click)="autosentOptionalEmailOTP('email')" href="javascript:void(0);" class="resend">Resend OTP</a>
            <!-- <a class="resend" *ngIf="emailOptionalotpInt != '00:00'" style="color: #ccc;opacity: 0.5;">Resend OTP   </a>-->

          </span>

          <div class="invalid-feedback _oth_requiered _time_otp"
            style="display: block !important; text-align:center !important;">
            <span *ngIf="emailOptionalotpInt != '00:00' || error_code">
            </span>
          </div>
          <div class="invalid-feedback _oth_requiered ">
            <span style="text-align: center;">{{optional_email_otp_success_msg}}</span>
            <!-- <span *ngIf="optional_email_error_code != '429'" style="text-align: center;">{{optional_email_otp_success_msg}}</span>
            <span *ngIf="optional_email_error_code == '429'" style="text-align: center;">{{optional_email_otp_success_msg}}</span> -->
          </div>

          <button class="btn btn-default auth-btn profile-btn"
            [disabled]="!addOptionalEmailOTPForm.valid">Confirm</button>

          <a href="#" #showOptionalEmailSuccess data-toggle="modal" data-target="#optional_email_change_success"></a>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Optional Email change otp -->


<!-- Optional Email change Success-->
<div class="modal  auth" id="optional_email_change_success">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" #closeSuccessOptionalEmailOTPModal>&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <div class="success-add">
          <img src="../../../assets/img/success.png">
          <!-- <p>Your email ID has been successfully changed.</p> -->
          <p *ngIf="registeredBy == 'mobile'">Your email id has been successfully added!</p>
          <p *ngIf="registeredBy == 'email'">Your mobile number has been successfully added!</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Parental Control Success-->


<!--Add frist time Optional mobile-->
<div class="modal  auth" id="optional-add-mobile">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Add Your Mobile Number</h4>
        <button type="button" class="close" data-dismiss="modal" #closeOptionalMobilebutton (click)="closeOptionalMobileBtn()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="addOptionalMobileForm" (ngSubmit)="sentOptionalEmailOTP('addmobile')">
          <div class="form-group input-group ">
            <input type="text" class="form-control" id="enter_optional_mobile" formControlName="add_optional_mobile"
              autocomplete="off" minlength="10" maxlength="10" placeholder="Enter Your Mobile Number"
              (keyup)="clearEmailError($event)">


            <div class="error _require_filed"><span>{{email_error}}</span></div>

          </div>


          <button class="btn btn-default auth-btn profile-btn" [disabled]="!addOptionalMobileForm.valid">Submit</button>
          <a href="#" #showOptionalEmailOTPModal data-toggle="modal" data-target="#optional_email_otp"></a>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Add Optional mobile end-->


<!-- change Optional mobile   ----------------- -->
<div class="modal  auth" id="optional-change-mobile">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Change Your Mobile Number</h4>
        <button type="button" class="close" data-dismiss="modal" #closeOptionalMobileChangebutton (click)="closeOptionalMobileChangeBtn()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form [formGroup]="changeOptionalMobileForm" (ngSubmit)="sentOptionalEmailOTP('changemobile')">
          <div class="form-group input-group ">

            <input type="number" class="form-control" id="enter_optional_mobile"
              formControlName="change_optional_mobile" autocomplete="off" minlength="10" maxlength="10"
              placeholder="Enter Your Mobile Number" (keyup)="clearEmailError($event)" (keypress)="commonService.mobileLimit($event)">
              
            <div
              *ngIf="changeOptionalMobileForm.get('change_optional_mobile').invalid && (changeOptionalMobileForm.get('change_optional_mobile').dirty || changeOptionalMobileForm.get('change_optional_mobile').touched)"
              class="error _require_filed">
              <span *ngIf="changeOptionalMobileForm.get('change_optional_mobile').errors.required">
                Please enter Mobile Number.
              </span>
              <span *ngIf="changeOptionalMobileForm.get('change_optional_mobile').errors?.pattern">
                Please enter valid Mobile Number.
              </span>
            </div>

            <div class="error _require_filed">
              <span *ngIf="optional_mobile_error!=''">{{optional_mobile_error}}</span>
              <span *ngIf="email_error!=''">{{email_error}}</span>
            </div>

          </div>

          <button class="btn btn-default auth-btn profile-btn"
            [disabled]="!changeOptionalMobileForm.valid">Submit</button>
          <!-- <a href="#" #showOptionalEmailOTPModal data-toggle="modal" data-target="#optional_email_otp"></a> -->
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Optional mobile change end----------------------- -->

<!--Start Update Profile Modal --->
<div class="modal auth tellu" id="UpdateProfileSetting">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Edit Profile</h4>
        <button type="button" class="close" data-dismiss="modal" #closeProfileForm>&times;</button>
      </div>
      <div class="modal-body">
        <form [formGroup]="updateProfileForm" (ngSubmit)="UpdateProfileSetting()" class="resettwo tellus_view">
          <!-- <div *ngIf="UpdateSuccessMsg">
            <div class="success-add">
               <img src="../../../assets/img/success.png">
              <h2 style="color:#fff;margin-top: 80px !important;font-size: 20px;font-weight: 100;line-height: 35px;"
                class="_verify_t">
                Your Profile Update successfully
              </h2>
            </div>
          </div> -->
          <div *ngFor="let tell of tellUsAllFieldsArray; let indexOfelement=index;">
            <div *ngIf="tell.type=='input'" class="form-group">
              <div class="row">
                <div class="col-md-12">

                  <div class="scroll_height">
                    <input type="text" class="form-control" [formControlName]="tell.slug" placeholder="{{tell.title}}">
                  </div>
                  </div>
                  <div class="col-md-12">
                  <div *ngIf='isTellUsSubmitted && tellFormControls[tell.slug]errors' class="_require_filed">
                    <span
                      *ngIf='tellFormControls[tell.slug].hasError("required") && (tellFormControls[tell.slug].dirty || tellFormControls[tell.slug].touched || tellFormControls[tell.slug].errors.required)'>
                      {{tell.error_message}}
                    </span>
                  </div>

                </div>
              </div>
            </div>
            <div *ngIf="tell.type=='input_calender'" class="form-group">
              <div class="row">
                <div class="col-md-4">
                  <label class="tellus_view_label">{{tell.title}}</label>
                </div>

                
                  <div class="col-md-8">
                    <!-- <input type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control datepicker" formControlName="{{tell.slug}}"
                    bsDatepicker [maxDate]="maxDate">  -->
                     <input type="text"
                    placeholder="DD/MM/YYYY"
                    class="form-control datepicker" formControlName="mycustomDate{{indexOfelement}}"
                    bsDatepicker [maxDate]="maxDate" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', adaptivePosition: true }"> 
                  </div>

                  <div class="col-md-12">
                    <div *ngIf="isTellUsSubmitted && ['mycustomDate'+indexOfelement]" class="_require_filed">
                      <span
                        *ngIf='tellFormControls["mycustomDate"+indexOfelement].hasError("required") && (tellFormControls["mycustomDate"+indexOfelement].dirty || tellFormControls["mycustomDate"+indexOfelement].touched || tellFormControls["mycustomDate"+indexOfelement].errors.required)'>
                        {{tell.error_message}}
                      </span>

                    </div>
                  </div>
                
              </div>
            </div>
            <div class="form-group" *ngIf="tell.type=='radio'">
              <div class="row">
                <div class="col-md-4">
                  <label class="tellus_view_label">{{tell.title}} </label>
                </div>
                <div class="col-md-8">
                  <ul>
                    <!--*ngFor="let rad of tell.attributes"-->
                    <li *ngFor="let rad of tell.attributes">
                      <label class="gander_radio">
                        <input type="radio" name="{{tell.slug}}" formControlName="{{tell.slug}}"
                          value="{{rad}}">{{rad}}</label>
                      </li>
                    
                  </ul>
                  <div class="col-md-12">
                    <div *ngIf='isTellUsSubmitted && tellFormControls[tell.slug].errors' class="_require_filed">
                      <span
                        *ngIf='tellFormControls[tell.slug].hasError("required") && (tellFormControls[tell.slug].dirty || tellFormControls[tell.slug].touched || tellFormControls[tell.slug].errors.required)'>
                        {{tell.error_message}}
                      </span>

                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <button type="submit" class="btn btn-default register _otp_reg">Submit</button>
        </form>
      </div>
    </div>
  </div>
</div>
<!--End Update Profile Modal --->

<!-- delete my account -->
<div class="modal  auth" id="account_delete">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">
      <div class="modal-header mb-3">
        <h4 class="modal-title">You're about to delete your account!</h4>
      </div>
      <div class="modal-body">
        <div class="success-add">
          <p class="light-font">Deleting your account will permanently delete your profile and if you have any active subscriptions, they will be canceled.  This cannot be undone.



            It may take 15 days for us to complete your request.
          </p>
          <button type="button" class="btn button btn button mr-3" (click)="continueButton()">Continue</button>
          <button type="button" class="close" data-dismiss="modal" class="btn button" #clbutton>Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
<button type="button" data-toggle="modal" data-dismiss="modal" data-target="#account_delete_confirm"  [hidden]="true" class="btn button btn button mr-3" #clickSuccessButton></button>

<div class="modal  auth" id="account_delete_confirm">
  <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
    <div class="modal-content">
      <div class="modal-header mb-3">
        <h4 class="modal-title">Account Deleted</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="success-add">
          <p class="light-font">Your account has been deleted. Thank you for using Zonet Empai.</p>
        </div>
      </div>
    </div>
  </div>
</div>